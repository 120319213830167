<template>
    <div>
        <b-row>
            <b-col cols="12">
                <h1
                    class="text-center font-title"
                    style="letter-spacing: 0.5rem"
                >
                    东湖国际中心制冷系统优化控制系统
                </h1>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <b-btn variant="primary" class="mr-1">负荷预测</b-btn>
                <b-btn variant="primary" class="mr-1">全局寻优</b-btn>
                <b-btn variant="primary">动态仿真</b-btn>
            </b-col>
        </b-row>

        

        <b-row class="mt-2">
            <b-col cols="2">
                <b-card>
                    <b-card-title class="card-title"
                        ><strong>能耗</strong></b-card-title
                    >

                    <b-alert show>Default Alert</b-alert>

                    <b-alert show variant="primary"
                        >今日建议开机时间为8:00，关机时间为18:00</b-alert
                    >

                    <b-card-text>
                        <p>当前冷冻水出水温度偏高，建议调整为10℃</p>
                        <p>当前冷冻泵频率过高，建议调整为40Hz</p>
                        <p>当前冷却泵频率事宜，建议保持</p>
                    </b-card-text>

                    <b-button variant="primary" size="sm">更多</b-button>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { $themeColors } from "@themeConfig";
import { kFormatter } from "@/@core/utils/filter";
import { onMounted, ref } from "@vue/composition-api";
import { setResize } from "@/libs/forge/utils/tools";
import { setupViewer } from "@/libs/forge/app";

export default {
    name: "",
    components: {},
    props: {},
    setup() {},
    data() {},
    watch: {},
    methods: {},
};
</script>

<style>
.card-title {
    border-left: 5px solid #1890ff;
    margin-left: -21px;
    padding-left: 16px;
}
</style>
